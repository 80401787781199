import { Box } from "@mui/material";
import * as React from "react";
import { useAuth } from "../../Authentication/AuthContext";
import CourseGrid from "./courseGrid";
import CourseTiles from "./courseTiles";
import Header from "../../components/Header";

function Courses() {
  const { user } = useAuth();
  return (
    <Box m="20px">
      <Header title="Courses" subtitle="List of Courses" />
      {user?.userType === "admin" ? <CourseGrid /> : <CourseTiles />}
    </Box>
  );
}

export default Courses;
