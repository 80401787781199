import { tokens } from "../../theme";
import ModalWindow from "./modal";
import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, TextField, MenuItem, Button } from "@mui/material";
import axios from "axios";
import * as React from "react";

const CourseGrid = () => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [modalContent, setModalContent] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const daysText = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "course_id", headerName: "ID" },
    {
      field: "area",
      headerName: "Course Area",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: "Course Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "group",
      headerName: "Age Group",
      flex: 1,
    },
    {
      field: "instructor",
      headerName: "Instructor",
      flex: 1,
    },
    {
      field: "days",
      headerName: "Offered On",
      flex: 1,
    },
    {
      field: "start",
      headerName: "Start Date",
      type: "date",
      flex: 1,
    },
    {
      field: "end",
      headerName: "End Date",
      type: "date",
      flex: 1,
    },
    {
      // field: "end",
      headerName: "Students",
      // type: "date",
      flex: 1,
    },
  ];

  useEffect(() => {
    const daysText = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const fetchData = async () => {
      try {
        const res = await axios.get("http://192.168.0.88:8800/courses", {
          withCredentials: true,
        });
        const formattedData = res.data.map((event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
          group:
            event.group === 1
              ? "Level 1: 4-6 years"
              : event.group === 2
              ? "Level 2: 7-9 years"
              : "Level 3: 10-15 years",
          days: JSON.parse(event.days)
            .map((day) => daysText[day])
            .join(", "),
        }));
        setEvents(formattedData);
        setFilteredEvents(formattedData); // Initially, show all events
      } catch (e) {
        console.error("Failed to fetch events data:", e);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const filterEvents = () => {
      let filtered = events;

      if (selectedDays.length > 0) {
        filtered = filtered.filter((event) =>
          selectedDays.some((day) => event.days.includes(day))
        );
      }

      if (selectedGroup) {
        filtered = filtered.filter((event) => event.group === selectedGroup);
      }
      console.log(selectedDays, selectedGroup);

      setFilteredEvents(filtered);
    };
    filterEvents();
  }, [selectedDays, selectedGroup, events]);

  const handleResetFilters = () => {
    setSelectedDays([]);
    setSelectedGroup("");
    setFilteredEvents(events); // Reset to show all events
  };

  const handleCellClick = (params) => {
    if (params.field === "days") {
      setModalContent(params.row);
      setModalOpen(true);
    }
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <><Box m="20px 0 20px 0" display="flex" gap="20px">
    {/* Day filter */}
    <TextField
      select
      label="Filter by Days"
      value={selectedDays}
      onChange={(e) => setSelectedDays(e.target.value)}
      SelectProps={{
        multiple: true,
      }}
      variant="outlined"
      fullWidth
    >
      {daysText.map((day, index) => (
        <MenuItem key={index} value={day}>
          {day}
        </MenuItem>
      ))}
    </TextField>

    {/* Age group filter */}
    <TextField
      select
      label="Filter by Age Group"
      value={selectedGroup}
      onChange={(e) => setSelectedGroup(e.target.value)}
      variant="outlined"
      fullWidth
    >
      <MenuItem value="">All Groups</MenuItem>
      <MenuItem value="Level 1: 4-6 years">Level 1: 4-6 years</MenuItem>
      <MenuItem value="Level 2: 7-9 years">Level 2: 7-9 years</MenuItem>
      <MenuItem value="Level 3: 10-15 years">Level 3: 10-15 years</MenuItem>
    </TextField>
    <Button
      variant="contained"
      color="primary"
      onClick={handleResetFilters}
      sx={{ alignSelf: "flex-end" }}
    >
      Reset
    </Button>
  </Box>

  <Box
    m="20px 0 0 0"
    height="75vh"
    sx={{
      "& .MuiDataGrid-root": {
        border: "none",
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none",
      },
      "& .name-column--cell": {
        color: colors.greenAccent[300],
      },
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: `${colors.blueAccent[700]} !important`,
        borderBottom: "none",
      },
      "& .MuiDataGrid-columnHeadersInner": {
        backgroundColor: `${colors.blueAccent[700]} !important`,
      },
      "& .MuiDataGrid-container--top [role=row]": {
        backgroundColor: `${colors.blueAccent[700]} !important`,
      },
      "& .MuiDataGrid-virtualScroller": {
        backgroundColor: colors.primary[400],
      },
      "& .MuiDataGrid-footerContainer": {
        borderTop: "none",
        backgroundColor: colors.blueAccent[700],
      },
      "& .MuiCheckbox-root": {
        color: `${colors.greenAccent[200]} !important`,
      },
      "& .MuiDataGrid-toolbarContainer": {
        color: colors.grey[100],
      },
      "& .MuiButton-text": {
        color: `${colors.grey[100]} !important`,
      },
    }}
  >
    <DataGrid
      rows={filteredEvents}
      columns={columns}
      slots={{ toolbar: GridToolbar }}
      components={{ Toolbar: GridToolbar }}
      onCellClick={handleCellClick}
      getRowId={(row) => row.course_id}
    />
  </Box>
  <ModalWindow
    modalOpen={modalOpen}
    handleCloseModal={handleCloseModal}
    modalContent={modalContent}
  /></>
  );
};

export default CourseGrid;
