import { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Topbar from "./scenes/global/TopBar";
import Sidebar from "./scenes/global/SideBar";
import Team from "./scenes/team";
import Contacts from "./scenes/contacts";
import Courses from "./scenes/courses";
import Calendar from "./scenes/calendar";
import Logout from "./Authentication/logout";
import Events from "./scenes/events";
import AddEvent from "./scenes/addEvent";
import AddCourse from "./scenes/addCourse";
import { AuthProvider, useAuth } from "./Authentication/AuthContext";

function Admin() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { user } = useAuth();
  const Navigate = useNavigate();
  console.log(user, "from admin");

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main className="content">
              <Topbar setIsSidebar={setIsSidebar} />
              <Routes>
                <Route path="/" />
                {/* {user === "admin" ? (
                  <Route path="team" element={<Team />} />
                ) : null} */}
                <Route path="team" element={<Team />} />
                <Route path="contacts" element={<Contacts />} />
                <Route path="courses" element={<Courses />} />
                <Route path="events" element={<Events />} />
                <Route path="add-event" element={<AddEvent />} />
                <Route path="add-course" element={<AddCourse />} />
                <Route path="calendar" element={<Calendar />} />
                <Route path="logout" element={<Logout />} />
              </Routes>
              {/* <Routes>
                <Route
                  path="/"
                  element={
                    user ? (
                      <Navigate to="/dashboard" />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="team"
                  element={user ? <Team /> : <Navigate to="/login" />}
                />
                <Route
                  path="contacts"
                  element={user ? <Contacts /> : <Navigate to="/login" />}
                />
                <Route
                  path="courses"
                  element={user ? <Courses /> : <Navigate to="/login" />}
                />
                <Route
                  path="events"
                  element={user ? <Events /> : <Navigate to="/login" />}
                />
                <Route
                  path="add-event"
                  element={user ? <AddEvent /> : <Navigate to="/login" />}
                />
                <Route
                  path="add-course"
                  element={user ? <AddCourse /> : <Navigate to="/login" />}
                />
                <Route
                  path="calendar"
                  element={user ? <Calendar /> : <Navigate to="/login" />}
                />
                <Route
                  path="logout"
                  element={user ? <Logout /> : <Navigate to="/login" />}
                />
              </Routes> */}
            </main>
          </div>
        </AuthProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default Admin;
