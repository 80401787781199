import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const CourseForm = () => {
  const areas = [
    { value: "SCIENTIFIC EXPLORATION", label: "SCIENTIFIC EXPLORATION" },
    { value: "ROBOTICS AND CODING", label: "ROBOTICS AND CODING" },
    { value: "ENTREPRENEURSHIP", label: "ENTREPRENEURSHIP" },
    { value: "CREATIVE DESIGN", label: "CREATIVE DESIGN" },
  ];

  const group = [
    { value: "1", label: "4-6 Years" },
    { value: "2", label: "7-9 Years" },
    { value: "3", label: "10-15 Years" },
  ];

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const checkoutSchema = yup.object().shape({
    // Add validation logic if needed
  });

  const [timeRanges, setTimeRanges] = useState([]);
  const [data, setData] = useState([]);

  // Days of the week mapped to their respective indices
  const daysOfWeek = [
    { label: "Sun", value: 0 },
    { label: "Mon", value: 1 },
    { label: "Tue", value: 2 },
    { label: "Wed", value: 3 },
    { label: "Thu", value: 4 },
    { label: "Fri", value: 5 },
    { label: "Sat", value: 6 },
  ];

  const handleTimeChange = (index, field, value) => {
    const newTimeRanges = [...timeRanges];
    if (!newTimeRanges[index]) {
      newTimeRanges[index] = { start: "", end: "" };
    }
    newTimeRanges[index][field] = value;
    setTimeRanges(newTimeRanges);
  };

  const handleDayChange = (event, setFieldValue) => {
    const { value, checked } = event.target;
    let updatedDays = [...data];
    if (checked) {
      updatedDays.push(value);
    } else {
      updatedDays = updatedDays.filter((d) => d !== value);
    }

    // Sort days based on the mapping
    updatedDays.sort((a, b) => {
      return (
        daysOfWeek.find((day) => day.label === a).value -
        daysOfWeek.find((day) => day.label === b).value
      );
    });

    setFieldValue("days", updatedDays);
    setData(updatedDays);
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <>
      <Typography variant="h2" component="h2" align="center" mb="10px">
        Add Course
      </Typography>
      <Formik
        initialValues={{
          course: "",
          area: "",
          group: "",
          inst: "",
          days: [],
          start: "",
          end: "",
          time: [],
        }}
        onSubmit={(values) => {
          const timeRangeStrings = timeRanges.map(
            (range) => `${range.start}-${range.end}`
          );
          values.time = timeRangeStrings;
          handleFormSubmit(values);
        }}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex" }}>
              {/* LEFT COLUMN */}
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                width="50%"
                marginRight="5%"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Course Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.course}
                  name="course"
                  error={!!touched.course && !!errors.course}
                  helperText={touched.course && errors.course}
                  sx={{ gridColumn: "span 4" }}
                />
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{ gridColumn: "span 4" }}
                  error={!!touched.area && !!errors.area}
                >
                  <InputLabel>Area</InputLabel>
                  <Select
                    name="area"
                    value={values.area}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Area"
                  >
                    {areas.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{touched.area && errors.area}</FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{ gridColumn: "span 4" }}
                  error={!!touched.group && !!errors.group}
                >
                  <InputLabel>Age Group</InputLabel>
                  <Select
                    name="group"
                    value={values.group}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="group"
                  >
                    {group.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched.group && errors.group}
                  </FormHelperText>
                </FormControl>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name of Instructor"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.inst}
                  name="inst"
                  error={!!touched.inst && !!errors.inst}
                  helperText={touched.inst && errors.inst}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Start Date"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.start}
                  name="start"
                  error={!!touched.start && !!errors.start}
                  helperText={touched.start && errors.start}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="End Date"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.end}
                  name="end"
                  error={!!touched.end && !!errors.end}
                  helperText={touched.end && errors.end}
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
              {/* RIGHT COLUMN */}
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                height="fit-content"
                width="50%"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                {daysOfWeek.map((day) => (
                  <FormControlLabel
                    sx={{ margin: "0px", width: "100%", height: "2em" }}
                    key={day.label}
                    control={
                      <Checkbox
                        sx={{ padding: "2px" }}
                        name={day.label}
                        value={day.label}
                        checked={values.days.includes(day.label)}
                        onChange={(event) =>
                          handleDayChange(event, setFieldValue)
                        }
                        color="primary"
                      />
                    }
                    label={day.label}
                  />
                ))}

                {data.map((day, index) => {
                  const dayIndex = daysOfWeek.find(
                    (dayOfWeek) => dayOfWeek.label === day
                  ).value;
                  return (
                    <React.Fragment key={day}>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="time"
                        label={`Start Time (${day})`}
                        onBlur={handleBlur}
                        onChange={(e) =>
                          handleTimeChange(dayIndex, "start", e.target.value)
                        }
                        value={timeRanges[dayIndex]?.start || ""}
                        name={`${day}_start`}
                        error={!!touched.start && !!errors.start}
                        helperText={touched.start && errors.start}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="time"
                        label={`End Time (${day})`}
                        onBlur={handleBlur}
                        onChange={(e) =>
                          handleTimeChange(dayIndex, "end", e.target.value)
                        }
                        value={timeRanges[dayIndex]?.end || ""}
                        name={`${day}_end`}
                        error={!!touched.end && !!errors.end}
                        helperText={touched.end && errors.end}
                        sx={{ gridColumn: "span 2" }}
                      />
                    </React.Fragment>
                  );
                })}
              </Box>
            </Box>
            <Box>
              {/* BUTTON SUBMIT */}
              <Box display="flex" justifyContent="center" mt="20px">
                <Button type="submit" variant="contained">
                  Add Course
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CourseForm;
