import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  Button,
  Typography,
} from "@mui/material";

const EventForm = () => {
  const areas = [
    { value: "SCIENTIFIC EXPLORATION", label: "SCIENTIFIC EXPLORATION" },
    { value: "ROBOTICS AND CODING", label: "ROBOTICS AND CODING" },
    { value: "ENTREPRENEURSHIP", label: "ENTREPRENEURSHIP" },
    { value: "CREATIVE DESIGN", label: "CREATIVE DESIGN" },
  ];

  const group = [
    { value: "1", label: "4-6 Years" },
    { value: "2", label: "7-9 Years" },
    { value: "3", label: "10-15 Years" },
  ];
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const handleFormSubmit = (values) => {
    console.log(values);
  };
  const initialValues = {
    area: "",
    event: "",
    group: "",
    inst: "",
    date: "",
    start: "",
    end: "",
  };

  // Regex for validating time format (24-hour format, e.g., "14:30")
  const timeRegExp = /^([01]\d|2[0-3]):([0-5]\d)$/;

  const checkoutSchema = yup.object().shape({
    area: yup.string().required("Area is required"),
    event: yup.string().required("Event is required"),
    group: yup.string().required("Age is required"),
    inst: yup.string().required("Institution is required"),
    date: yup
      .date()
      .required("Date is required")
      .typeError("Invalid date format"),
    start: yup
      .string()
      .matches(timeRegExp, "Start time must be in HH:MM format")
      .required("Start time is required"),
    end: yup
      .string()
      .matches(timeRegExp, "End time must be in HH:MM format")
      .required("End time is required"),
  });

  return (
    <>
      <Typography variant="h2" component="h2" align="center" mb="10px">
        Add Event
      </Typography>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Event Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.event}
                name="event"
                error={!!touched.event && !!errors.event}
                helperText={touched.event && errors.event}
                sx={{ gridColumn: "span 4" }}
              />
              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
                error={!!touched.area && !!errors.area}
              >
                <InputLabel>Area</InputLabel>
                <Select
                  name="area"
                  value={values.area}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Area"
                >
                  {areas.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{touched.area && errors.area}</FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
                error={!!touched.group && !!errors.group}
              >
                <InputLabel>Age Group</InputLabel>
                <Select
                  name="group"
                  value={values.group}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="group"
                >
                  {group.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{touched.group && errors.group}</FormHelperText>
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name of Instructor"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.inst}
                name="inst"
                error={!!touched.inst && !!errors.inst}
                helperText={touched.inst && errors.inst}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.date}
                name="date"
                error={!!touched.date && !!errors.date}
                helperText={touched.date && errors.date}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="time"
                label="Start Time"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.start}
                name="start"
                error={!!touched.start && !!errors.start}
                helperText={touched.start && errors.start}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="time"
                label="End Time"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.end}
                name="end"
                error={!!touched.end && !!errors.end}
                helperText={touched.end && errors.end}
                sx={{ gridColumn: "span 1" }}
              />
            </Box>
            <Box display="flex" justifyContent="center" mt="20px">
              <Button type="submit" variant="contained">
                Add Event
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EventForm;
