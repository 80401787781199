import React from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import * as yup from "yup";

const initialValues = {
  username: "",
  password: "",
};

const userSchema = yup.object().shape({
  username: yup.string().required("Required field"),
  password: yup.string().required("Required field"),
});

const Login = () => {
  // const theme = useTheme();
  const isDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { login } = useAuth(); // Destructure login function from context
  const navigate = useNavigate(); // Get navigate function

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      await login(values);
      console.log("navigating");
      navigate("/dashboard");
    } catch (err) {
      console.error("Login failed", err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      m="0"
      p="0"
      className="login"
    >
      <Box
        width="50vw"
        height="100vh"
        m="0"
        p="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "relative",
          backgroundImage:
            "url('https://upstudio.africa/wp-content/uploads/2022/11/UpStudioWebsiteRefresh1.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center left", // Center the image
        }}
      ></Box>
      <Box
        width="50vw"
        height="100vh"
        m="0"
        p="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: isDarkMode ? "black" : "white" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <img
            src="https://upstudio.africa/wp-content/uploads/2022/11/Logoinpill3-300x126.png"
            alt="Logo"
            style={{ width: "100px", height: "auto" }}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "#ffd33d",
            padding: "4rem",
            borderRadius: "5rem",
          }}
        >
          <Typography variant="h4" sx={{ textAlign: "center", mb: "20px" }}>
            {" "}
            Sign In
          </Typography>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={userSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="User Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    error={!!touched.username && !!errors.username}
                    helperText={touched.username && errors.username}
                    sx={{
                      gridColumn: "span 12",
                      backgroundColor: "rgba(255, 211, 61, 0.5)",
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="password"
                    label="Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 12" }}
                  />
                </Box>
                <Box display="flex" justifyContent="center" mt="20px">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ backgroundColor: "#f0513e" }}
                  >
                    Sign In
                  </Button>
                </Box>
                {/* <Box
                  display="flex"
                  justifyContent="center"
                  mt="10px"
                  sx={{ color: isDarkMode ? "white" : "black" }}
                >
                  <a>Register?</a>
                </Box> */}
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
