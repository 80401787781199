import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.post(
          "http://192.168.0.88:8800/logout",
          {},
          { withCredentials: true }
        );
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();

    // Clear the token from localStorage or any other storage mechanism
    localStorage.removeItem("access-token");

    // Redirect the user to the login page
    navigate("/login", { replace: true });

    // Optional: You can also clear any global state or context here if needed
  }, [navigate]);

  return null; // Since we are redirecting, no need to render anything
}

export default Logout;
