import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null); // Use null for no use
  const navigate = useNavigate();

  // Check authentication on initial load
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const res = await axios.get("http://192.168.0.88:8800/authorized", {
          withCredentials: true,
        });
        if (res.data.auth) {
          setIsAuthenticated(true);
          setUser(res.data);
          navigate("/"); // Redirect if authenticated
        }
      } catch (error) {
        console.error("Auth check failed", error);
        setIsAuthenticated(false);
        // setUser(null);
      }
    };

    checkAuthentication();
  }, []);

  const login = async (credentials) => {
    try {
      const response = await axios.post(
        "http://192.168.0.88:8800/login",
        credentials,
        { withCredentials: true }
      );
      if (response.data.auth) {
        setIsAuthenticated(true);
        // localStorage.setItem("access-token", "access-token");
        if (response.data.d_name) {
          console.log("Adding to local storage");
          localStorage.setItem("user", response.data.d_name);
        }
        if (response.data.role) {
          localStorage.setItem("role", response.data.role);
        }
        navigate("/"); // Redirect after successful login
      }
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  const logout = async () => {
    // localStorage.removeItem("access-token");
    localStorage.removeItem("user");
    localStorage.removeItem("role");
    try {
      await axios.post(
        "http://192.168.0.88:8800/logout",
        { withCredentials: true }
      );
    } catch (error) {
      console.error("Logout failed", error);
    }
    // setUser(null);
    setIsAuthenticated(false);
    navigate("/login"); // Redirect to login page after logout
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
